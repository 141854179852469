.hg-theme-default {
  background: black;

  .hg-standardBtn {
    background: #818384;
    font-weight: 600;
    color: white;
  }

  .hg-button-bksp {
    background: #818384;
    font-weight: 600;
    color: white;
  }
}
